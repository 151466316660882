<template>
  <div class="container">
    <Logo class="logo" />
    <v-alert class="alert" dense outlined v-if="alert">{{message}}</v-alert>
    <div class="titleForgotPassword">Esqueceu a senha?</div>
    <div class="descriptionForgotPassword">
      Informe seu CNPJ ou e-mail abaixo para procurarmos a sua conta.
      Você receberá um e-mail com instruções para recuperar seu acesso.
    </div>
    <hr class="mb-3" />
    <v-col cols="12" md="12" class="pa-md-0 text pa-1" align="center">
        Escolha de qual forma deseja recuperar seu login:
    </v-col>
    <v-row>
      <v-col cols="6" class="pb-0">
        <v-btn
          block dark
          color="#282E62"
          :outlined="typeLogin === 'email'"
          @click="setTypeLogin('cnpj')"
          >
          CNPJ
        </v-btn>
      </v-col>
      <v-col cols="6" class="pb-2">
        <v-btn
          block dark
          color="#282E62"
          @click="setTypeLogin('email')"
          :outlined="typeLogin === 'cnpj'"
          >
          E-mail
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-0" >
        <v-form  ref="form"  @submit.prevent="submit">
          <v-text-field
            v-if="typeLogin === 'cnpj'"
            v-model="form.cnpj"
            v-mask="'##.###.###/####-##'"
            label="CNPJ"
            type="text"
            :readonly="enable"
            :rules="rulesLogin"
          ></v-text-field>
          <v-col cols="12" v-if="typeLogin === 'email'" class="pa-0">
            <v-text-field
              v-model="form.email"
              label="E-mail"
              type="text"
              :readonly="enable"
              :rules="validateEmail"
            ></v-text-field>
          </v-col>
        </v-form>

        <v-btn
          rounded
          large
          block
          color="#D70472"
          @click="invitePassword()"
          dark
          class="button mt-8 mt-md-3"
        >Enviar</v-btn>
      </v-col>
      <v-col cols="12" class="py-0">
        <div class="buttons-forgot">
          <ReturnToLogin />
          <LinkRegistration />
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import Logo from '../../../components/Logo.vue';
import ReturnToLogin from '../../../components/returnToLogin.vue';
import LinkRegistration from '../../../components/LinkRegister.vue';
import service from '../../../service';

export default {
  name: 'forgotPassword',
  directives: {
    mask,
  },
  components: { Logo, ReturnToLogin, LinkRegistration },
  data: () => ({
    enable: false,
    form: {
      cnpj: '',
      email: ''
    },
    service,
    alert: false,
    typeLogin: 'cnpj'
  }),
  computed: {
    ...mapGetters({
      message: 'Auth/getMessagePassword',
      errorPassword: 'Auth/getErrorPassword',
    }),
    rulesLogin() {
      return [
        value => !!value || 'Informe seu CNPJ',
      ];
    },
    validateEmail(){
      return [
          valueInput => !!valueInput || "Informe seu e-mail",
          valueInput => /.+@.+\..+/.test(valueInput) || "E-mail inválido",
      ]
    }
  },
  watch: {
    message(value) {
      this.alert = !!value;
    },
  },
  methods: {
    ...mapActions({
      forgetPassword: 'Auth/forgetPassword',
    }),
    async invitePassword() {
      this.alert = false;
      if (!this.$refs.form.validate()) return

      const login = this.typeLogin === 'cnpj' ? this.service.cleanDocument(this.form.cnpj) : this.form.email;
      await this.forgetPassword({ login });

      if (this.errorPassword === true) {
        this.enable = false;
      } else {
        this.enable = true;
      }
    },
    setTypeLogin(type){
      if(this.typeLogin !== type){
        this.form.email = ""
        this.form.cnpj = ""
        this.typeLogin = type
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/commons/forgotPassword.scss';
</style>
