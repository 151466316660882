<template>
    <div class="container-link">
        <a class="link" @click="pushToLogin">Voltar para página de login</a>
    </div>
</template>

<script>
export default {
  name: 'ReturnToLogin',
  methods: {
    pushToLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style lang="scss" scoped>
  .container-link {
    margin-bottom: 16px;
    margin-top: 10px;
  }
  .link {
    color: #404040;
    font-weight: 300;
    text-decoration: underline;
  }
</style>
