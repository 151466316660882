<template>
    <div class="container-link">
        <a class="link" @click="pushToRegister">Quero me cadastrar</a>
    </div>
</template>

<script>
export default {
  name: 'LinkRegister',
  methods: {
    pushToRegister() {
      this.$router.push({ name: 'registration' });
    },
  },
};
</script>
<style lang="scss" scoped>
    .link {
        color: #404040;
        font-weight: 300;
        text-decoration: underline;
    }

    @media only screen and (max-device-width: 700px)  {
        /* For mobile phones: */
      
    }
</style>
